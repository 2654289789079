import React from "react"

import Fade from "@mui/material/Fade"
import Paper from "@mui/material/Paper"
import Modal from "@mui/material/Modal"
import IconButton from "@mui/material/IconButton"

import CloseIcon from "@mui/icons-material/Close"

import Signin from "../Authentication/Signin"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  outline: 0,
  width: 350
}
const LoginModal = ({ onSuccess, children }) => {
  const [open, setOpen] = React.useState(false)

  const handleOpenChange = () => {
    setOpen(!open)
  }

  const handleSuccess = async () => {
    if (onSuccess) {
      await onSuccess()
    }
    handleOpenChange()
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <div onClick={handleOpenChange}>{children}</div>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Paper style={style}>
          <div style={{ display: "flex", justifyContent: "flex-end", position: "relative", top: "10px" }}>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <Signin onFacebookLogin={handleSuccess} onGoogleLogin={handleSuccess} onSuccess={handleSuccess} />
        </Paper>
      </Modal>
    </>
  )
}

export default LoginModal
