/*
 * Copyright (C) 2020 Is Kibris - All Rights Reserved
 * Is Kibris Hiring - https://hiring.iskbris.com
 * Innovia - https://www.innoviadigital.biz
 *
 * Authors:
 * Diligence Vagere - diligence@innovia.biz
 *
 * Description:
 * Component for Facebook login/signup
 */

import React from "react"

import ReactFacebookLogin from "react-facebook-login"
import iskibrisapi from "../../../api/iskibris/client"

import facebook from "../../../../src/images/facebook.png"
import useStyles from "../styles"
import { AxiosError, AxiosResponse } from "axios"
import Cookies from "js-cookie"
import { useTranslation } from "react-i18next"
import Image from "next/image"
import { useRouter } from "next/router"

interface Props {
  mode: string
  onSuccess: () => void
  onFail: () => void
}

const facebookAppId = process.env.NEXT_PUBLIC_FACEBOOK_APP_ID ?? ""
const FacebookAuth: React.FC<Props> = props => {
  const classes = useStyles()
  const route = useRouter()
  const { t } = useTranslation()

  const [loading, setLoading] = React.useState(false)

  const facebookLoginHandler = async (response: any) => {
    setLoading(true)
    let accessToken = { access_token: response.accessToken }
    iskibrisapi
      .post("/api/login/facebook", accessToken)
      .then(async (response: AxiosResponse) => {
        Cookies.set("auth_token", response.data["token"], {
          domain: process.env.NEXT_PUBLIC_APP_DOMAIN ?? ".iskibris.com",
          sameSite: "None",
          secure: true,
          expires: 364
        })
        Cookies.set("is_employer", "true", {
          domain: process.env.NEXT_PUBLIC_APP_DOMAIN ?? ".iskibris.com",
          sameSite: "None",
          secure: true,
          expires: 750
        })
        localStorage.setItem("auth_token", response.data["token"])
        setLoading(false)
        props.onSuccess()
      })
      .catch((err: AxiosError) => {
        setLoading(false)
        props.onFail()
      })
  }

  return (
    <div style={{ justifyContent: "center", height: "86px", width: "100%" }}>
      <ReactFacebookLogin
        appId={facebookAppId}
        fields="email"
        cssClass={classes.facebookLoginButton}
        buttonStyle={{
          width: "100%",
          height: "50px",
          fontSize: "16px",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          borderRadius: "5px",
          boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12)",
          // fontWeight: "normal",
          textTransform: "none",
          border: "none"
        }}
        textButton={"Facebook ile giriş"}
        typeButton="contained"
        size="small"
        disableMobileRedirect={true}
        callback={facebookLoginHandler}
        icon={<Image alt="fb" src={facebook} width={30} height={25} className="_login-form facebook" />}
      />
      {/* {loading ? (
        <OverlayLoader
          handleClose={() => {
            setLoading(false)
          }}
          open
        />
      ) : null} */}
    </div>
  )
}

export default FacebookAuth
