/*
 * Copyright (C) 2020 Is Kibris - All Rights Reserved
 * Is Kibris Hiring - https://hiring.iskbris.com
 * Innovia - https://www.innoviadigital.com
 *
 * Authors:
 * Diligence Vagere - diligence@innovia.biz
 *
 * Description:
 * Component to divide between social logins and server logins.
 */

import React from "react"
import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"

import useStyles from "../styles"
import { useTranslation } from "react-i18next"

interface Props {}

const CustomDivider: React.FC<Props> = props => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.customDividerContainer}>
      <Divider className={classes.customDividerLine}></Divider>
      <Typography className={classes.customDividerContent}>{t("or")}</Typography>
    </div>
  )
}

export default CustomDivider
