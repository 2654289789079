import { useState, useEffect, useContext, forwardRef } from "react"

import Cookies from "js-cookie"
import iskibrisapi from "../api/iskibris/client"

import Link from "next/link"

import Button from "@mui/material/Button"

import UserMenu from "./UserMenu"

import AppStateContext from "../context/AppStateContext"
import DispatchContext from "../context/DispatchContext"
import LoginModal from "./LoginModal/LoginModal"
import MuiAlert from "@mui/material/Alert"

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

export default function Header() {
  const [menuOpen, setMenuOpen] = useState(false)

  const { AuthState } = useContext(AppStateContext)
  const { AuthDispatcher } = useContext(DispatchContext)
  const [open, setOpen] = useState(true)

  const handleClick = () => {
    setOpen(true)
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }

    setOpen(false)
  }

  const handleLogout = async () => {
    await iskibrisapi.post("/api/logout").then(response => {})
    localStorage.removeItem("auth_token")
    localStorage.removeItem("refresh_token")
    localStorage.removeItem("current_company_id")
    localStorage.removeItem("CompanyToken")
    await Cookies.remove("CompanyToken")

    await Cookies.remove("auth_token", { domain: process.env.NEXT_PUBLIC_APP_DOMAIN ?? ".iskibris.com" })

    AuthDispatcher({ type: "logout" })
  }

  async function getIskibrisProfile() {
    await iskibrisapi
      .get("/api/user/profile")
      .then(response => {
        const user = response.data
        const userval = user.is_employer
        // AuthDispatcher({ type: "addUser", payload: user })

        // if (userval) {
          AuthDispatcher({ type: "addUser", payload: user })
          return 1
        // } else {
        //   AuthDispatcher({ type: "logout" })
        //   return 0
        // }
      })
      .catch(error => {})
  }

  useEffect(() => {
    // console.log(">>> Header useEffect: attempt fetchProfile: isLoggedIn", AuthState.isLoggedIn)
    if (AuthState.isLoggedIn && !AuthState.user) {
      // console.log(">>> Header useEffect: fetchProfile on isLoggedIn true and no user: ", AuthState.isLoggedIn)
      getIskibrisProfile()
    }
  }, [AuthState.isLoggedIn, AuthState.user])

  const handleSuccess = async () => {
    AuthDispatcher({ type: "login" })
    await getIskibrisProfile()
  }

  const RenderMobileMenu = () => {
    if (!menuOpen) return null

    return (
      <div className="overlay">
        <div className="overlay__header">
          <button className="overlay__close" onClick={e => setMenuOpen(false)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
        <div className="overlay__inner">
          <div className="header__mobile-menu">
            <ul className="header__mobile-nav">
              <Link href="/" onClick={e => setMenuOpen(false)} passHref>
                <li className="header__mobile-nav__item" onClick={e => setMenuOpen(false)}>
                  Anasayfa
                </li>
              </Link>
              <Link href="/#features" onClick={e => setMenuOpen(false)} passHref>
                <li className="header__mobile-nav__item" onClick={e => setMenuOpen(false)}>
                  Özellikler
                </li>
              </Link>
              <Link href="/#pricing" onClick={e => setMenuOpen(false)} passHref>
                <li className="header__mobile-nav__item" onClick={e => setMenuOpen(false)}>
                  Ücretlendirme
                </li>
              </Link>
              <Link href="/#testimonials" onClick={e => setMenuOpen(false)} passHref>
                <li className="header__mobile-nav__item" onClick={e => setMenuOpen(false)}>
                  Referanslarımız
                </li>
              </Link>
              <Link href="/blog" onClick={e => setMenuOpen(false)} passHref>
                <li className="header__mobile-nav__item" onClick={e => setMenuOpen(false)}>
                  Blog
                </li>
              </Link>
              <Link href="/contact" onClick={e => setMenuOpen(false)} passHref>
                <li className="header__mobile-nav__item" onClick={e => setMenuOpen(false)}>
                  Bize Ulaşmak İçin
                </li>
              </Link>
              <Link href="/" onClick={e => setMenuOpen(false)} passHref>
                <li className="header__mobile-nav__item" onClick={e => setMenuOpen(false)}>
                  Destek Portalı
                </li>
              </Link>
              <Link href="https://hiring.iskibris.com" passHref>
                <li className="header__mobile-nav__item header__mobile-nav__item--alt">Giriş Yap</li>
              </Link>
            </ul>
          </div>
        </div>
      </div>
    )
  }
  return (
    <header className="site-header">
      <div className="row row--purple row--action header__top--height-reset">
        <div className="wrapper header__top">
          <strong></strong>
          <nav className="primary-nav primary-nav--pull-right">
            <ul className="header__nav">
              <li className="header__link header__link--hover-lightest">
                <Link href="/blog">Blog</Link>
              </li>
              <li className="header__link header__link--hover-lightest">
                <Link href="/contact">Bize Ulaşmak İçin</Link>
              </li>
              <li className="header__link header__link--hover-lightest">
                <a href="">Destek Portalı</a>
              </li>
              <li className="header__link header__link--hover-lightest">
                <Link href="https://hiring.iskibris.com">Giriş Yap</Link>
              </li>
            </ul>

            <div className="header__menu">
              <button onClick={e => setMenuOpen(true)} className="button header__menu-button">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20" fill="currentColor">
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>

              {RenderMobileMenu()}
            </div>
          </nav>
        </div>
      </div>

      <div className="row row--dark-blue header__bottom">
        <div className="flex-wrapper flex-wrapper__space-between">
          <Link href="/" className="d-inline-block link" style={{ cursor: "pointer" }} passHref>
            <img src="/logo_sticky_header.png" className="header__logo" />
          </Link>
          <nav className="primary-nav flex-wrapper__right">
            <ul className="header__nav">
              <li className="header__link header__link--hover-lightest">
                <Link href="/">Anasayfa</Link>
              </li>
              <li className="header__link header__link--hover-lightest">
                <Link href="/#features">Özellikler</Link>
              </li>
              <li className="header__link header__link--hover-lightest">
                <Link href="/#testimonial">Referanslar</Link>
              </li>
              <li className="header__link header__link--hover-lightest">
                <Link href="/#pricing">Ücretlendirme</Link>
              </li>
              <li className="header__link header__link--hover-lightest">
                <Link href="/#testimonials">Referanslarımız</Link>
              </li>
              <li>
                <Link href="https://hiring.iskibris.com">İşe Alım Ekranı</Link>
              </li>
            </ul>
            {/*   <div className="">
            </div> */}
            {/*  <Link href="/login" style={{ marginLeft: "10px" }}> */}
            {/* checking if user is logged in */}
            <div className={AuthState.isLoggedIn ? "primary-nav--pull-right" : "primary-nav--pull-right"}>
              {AuthState.user ? (
                <UserMenu onLogout={handleLogout} user={AuthState.user} />
              ) : (
                <LoginModal onSuccess={handleSuccess}>
                  <Button variant="text" style={{ color: "#b41870", marginLeft: "8%", fontSize: "18px", marginTop: "1px", textTransform: "none" }} size="large">
                    Giriş
                  </Button>
                </LoginModal>
              )}
            </div>
            {/*  </Link> */}
          </nav>
        </div>
      </div>
    </header>
  )
}
