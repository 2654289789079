import Link from "next/link"

export default function Footer() {
  return (
    <footer className="section section--gray">
      <div className="wrapper">
        <div className="row">
          <div className="row__medium-3 footer__address">
            <div>
              <img src="/iskibris_footer.png" alt="İş Kıbrıs Logo" width="160px" height="48px" />
            </div>
            <div className="heading heading--small">
              Şehit Cumhur Yüzlü Sokak <br /> Vega Apartmanı Daire: 12 <br /> Ortaköy, Lefkoşa
            </div>
            <div>
              <a href="https://goo.gl/maps/UYzvoM1DtajV7mNK8" target="_blank" rel="noreferrer">
                Konumumuz icin tıklayın
              </a>
            </div>
            <a href="mailto://info@iskibris.com">info@iskibris.com</a>
            <div>0542 857 0360</div>
          </div>
          <div className="row__medium-3 footer__section">
            <div className="headline headline--small">Site Kullanımı</div>
            <ul className="footer__nav">
              <li className="footer__item">
                <Link href="/terms-conditions">Şartlar & Koşullar</Link>
              </li>
              <li className="footer__item">
                <Link href="/privacy-policy">Gizlilik Politikamız</Link>
              </li>
            </ul>
          </div>
          <div className="row__medium-3 footer__section">
            <div className="headline headline--small">Firma İşlemleri</div>
            <ul className="footer__nav">
              <li className="footer__item">
                <Link href="#">İş İlanı Yayınla</Link>
              </li>
              <li className="footer__item">
                <Link href="#">Firma Üyeliği</Link>
              </li>
              <li className="footer__item">
                <Link href="https://hiring.iskibris.com">Firma Girişi</Link>
              </li>
            </ul>
          </div>
          <div className="row__medium-3 footer__section">
            <a href="https://play.google.com/store/apps/details?id=com.innoviadigital.iskibris&hl=en_US" target="_blank" rel="noreferrer">
              <div style={{ cursor: "pointer" }}>
                <img src="/google_play.jpeg" alt="Is Kibris Google Play" />
              </div>
            </a>
            <a href="https://apps.apple.com/us/app/i-%C5%9F-k%C4%B1br%C4%B1s/id1266248384" target="_blank" rel="noreferrer">
              <div style={{ cursor: "pointer", marginTop: "0.2rem" }}>
                <img src="/app_store.jpeg" alt="Is Kibris App Store" />
              </div>
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}
