import axios from "axios"
import Cookies from "js-cookie"

let iskibris = axios.create({
  baseURL: process.env.NEXT_PUBLIC_ISKIBRIS_API_URL,
  headers: {
    Accept: "application/json"
  }
})

iskibris.interceptors.request.use(request => {
  const authToken = Cookies.get("auth_token")
  const companyToken = Cookies.get("CompanyToken")

  if (authToken) {
    request.headers["Authorization"] = `Bearer ${authToken}`
  }

  if (companyToken) {
    request.headers["CompanyToken"] = `${companyToken}`
  }
  return request
})

iskibris.interceptors.response.use(
  response => {
    return response
  },
  function (error) {
    if (error.response?.status === 401) {
      // localStorage.removeItem("auth_token")
      // localStorage.removeItem("refresh_token")
      // localStorage.removeItem("current_company_id")
      // localStorage.removeItem("CompanyToken")
      Cookies.remove("auth_token", {
        domain: process.env.NEXT_PUBLIC_APP_DOMAIN ?? ".iskibris.com"
      })
      Cookies.remove("CompanyToken")
      // store.dispatch(action(actionTypes.UNAUTHENTICATE))
    }
    return Promise.reject(error)
  }
)

export default iskibris
