/*
 * Copyright (C) 2020 Is Kibris - All Rights Reserved
 * Is Kibris Hiring - https://hiring.iskbris.com
 * Innovia - https://www.innoviadigital.biz
 *
 * Authors:
 * Diligence Vagere - diligence@innovia.biz
 *
 * Description:
 * Component to allow google signin or signup
 */

import React from "react"
import Cookies from "js-cookie"
import { useRouter } from "next/router"

import { useTranslation } from "react-i18next"
import { AxiosError, AxiosResponse } from "axios"
import iskibrisapi from "../../../api/iskibris/client"

import GoogleLogin from "react-google-login"
import { Button } from "@mui/material"
//import OverlayLoader from "../../OverlayLoader"
import google from "../../../../src/images/google.png"

import useStyles from "../styles"
import Image from "next/image"
import { resolve } from "path"
import { route } from "next/dist/server/router"

interface Props {
  mode: string
  onSuccess: () => void
  onFail: () => void
  onAuthenticated: () => void
}
const googleClientId = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID ?? "598313681033-7kjod6030ts2rvr3guv1sjpmvuduen0e.apps.googleusercontent.com"

const GoogleOauth: React.FC<Props> = (props: Props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [loading, setLoading] = React.useState(false)

  const route = useRouter()
  const googleLoginHandler = (response?: any) => {
    setLoading(true)
    const accessToken = { access_token: response.accessToken }
    iskibrisapi
      .post("/api/login/google", accessToken)
      .then(async (response: AxiosResponse) => {
        Cookies.set("auth_token", response.data.token, {
          domain: process.env.NEXT_PUBLIC_APP_DOMAIN ?? ".iskibris.com",
          sameSite: "None",
          secure: true,
          expires: 364
        })
        Cookies.set("is_employer", "true", {
          domain: process.env.NEXT_PUBLIC_APP_DOMAIN ?? ".iskibris.com",
          sameSite: "None",
          secure: true,
          expires: 750
        })
        localStorage.setItem("auth_token", response.data["token"])
        props.onSuccess()
      })
      .catch((err: AxiosError) => {
        setLoading(false)
        props.onFail()
      })
  }
  //make a props in the main page and pass the function which is the google login to the header page that way it should be fixed
  return (
    <div style={{ justifyContent: "center" }}>
      <GoogleLogin
        clientId={googleClientId}
        onAutoLoadFinished={successLogin => {}}
        onSuccess={googleLoginHandler}
        onFailure={error => {
          props.onFail()
        }}
        render={ownProps => (
          <Button size="medium" className={classes.googleLoginButton} onClick={ownProps.onClick}>
            <Image src={google} width={30} height={30} className="_login-form google" alt="Google"></Image>
            Google ile giriş yap
          </Button>
        )}
      />
      {/*  {loading ? (
        <OverlayLoader
          handleClose={() => {
            setLoading(false)
          }}
          open
        />
      ) : null} */}
    </div>
  )
}

export default GoogleOauth
