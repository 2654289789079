/*
 * Copyright (C) 2020 Is Kibris - All Rights Reserved
 * Is Kibris Hiring - https://hiring.iskbris.com
 * Innovia - https://www.innoviadigital.biz
 *
 * Authors:
 * Diligence Vagere - diligence@innovia.biz
 *
 * Description:
 * Component which contains the greeting text
 */

import React, { useState } from "react"
import { Typography } from "@mui/material"
import useStyles from "../styles"
import { useTranslation } from "react-i18next"
import { AnySet } from "immer/dist/internal"
let greeting

const getGreetingMessage = () => {
  let timeOfTheDay = new Date()
  let morningEnd = new Date()
  const morningcheck = morningEnd.getHours()
  morningEnd.setHours(12, 0, 0)
  let afternoonEnd = new Date()
  afternoonEnd.setHours(17, 0, 0)
  //ddd

  if (timeOfTheDay <= morningEnd) {
    greeting = "Günaydın"
    return 0
  } else if (timeOfTheDay <= afternoonEnd) {
    greeting = "Tünaydın"
    return 1
  } else {
    greeting = "İyi akşamlar"
    return 2
  }
}
export const greetingMessage = getGreetingMessage()
export default function Greetings() {
  const classes = useStyles()
  const { t } = useTranslation()
  const [day, setday] = useState("")

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Typography variant="h4" className={classes.greetingMessage}>
        {greeting}
      </Typography>
    </div>
  )
}
