import React from "react"
import Menu from "@mui/material/Menu"
import Avatar from "@mui/material/Avatar"
import MenuItem from "@mui/material/MenuItem"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"

const UserMenu = ({ user, onLogout }) => {
  const [anchorElUser, setAnchorElUser] = React.useState(null)

  const handleOpenUserMenu = event => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  return (
    <>
      <IconButton className="primary-nav--pull-right" onClick={handleOpenUserMenu}>
        <Avatar alt="Remy Sharp" src={user?.avatar_url} />
        <Typography variant="body1" sx={{ color: "white", paddingLeft: "10px" }}>
          {user?.full_name}
        </Typography>
      </IconButton>
      <Menu
        sx={{ mt: "60px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem onClick={onLogout}>
          <Typography textAlign="center"> Çıkış Yap</Typography>
        </MenuItem>
      </Menu>
    </>
  )
}

export default UserMenu
